import * as React from "react"
import { Helmet } from "react-helmet"
import '../styles/mint.css'
const MintWrapper = React.lazy(() =>
  import("../components/mint/wrapper.js")
)

const MintPage = () => {
  const isSSR = typeof window === "undefined"
  return (
      <main>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Mint - CULT&RAIN&trade;</title>
          <link rel="canonical" href="https://www.cultandrain.com/mint" />
          <meta name="description" content="The first luxury fashion brand born from the crypto universe merging NFTs with exclusive, physical redeemables. Join our Web3 revolution." />
        </Helmet>
        <div className="mint-page">
        {!isSSR && (
            <React.Suspense fallback={<div />}>
                <MintWrapper />
            </React.Suspense>
        )}
        <video autoPlay loop muted>
          <source src="https://cdn.cultandrain.com/chamber loop 6 sec_.mp4" type="video/mp4" />
        </video>
        </div>
      </main>
  )
}

export default MintPage
